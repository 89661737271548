import { getGptAdById, getAllAds } from "./models";
import { log } from "./utils/log";

const OBSERVERS = {};

/**
 * Set up a 1.5 screen lazy-load window
 * Pubwise will override this if used.
 * @param {import("./gpt").GptAd}
 */
export function bindLazyLoadIntersectionObserver(gptAd) {
  if (!OBSERVERS["lazyload"]) {
    OBSERVERS["lazyload"] = new IntersectionObserver(
      (entries) => {
        entries.map((entry) => {
          const gptAd = getGptAdById(entry.target.id);
          gptAd.inLazyRange = entry.isIntersecting;
        });
      },
      {
        rootMargin: `0px 0px 150% 0px`,
      },
    );
  }
  const observer = OBSERVERS["lazyload"];

  // Always ensure we're not already observing the element.
  // This ensures if we call `GptAd.reset()` the ad will
  // go through the observer again like the brand new ad it is.
  observer.unobserve(gptAd.element);

  observer.observe(gptAd.element);
}

/**
 * Set up a in-screen viewer to use for refresh
 * Pubwise will override this if used.
 * @param {import("./gpt").GptAd}
 */
export function bindRefreshLoadIntersectionObserver(gptAd) {
  if (!OBSERVERS["lazyload-200"]) {
    OBSERVERS["refresh"] = new IntersectionObserver(
      (entries) => {
        entries.map((entry) => {
          const gptAd = getGptAdById(entry.target.id);
          gptAd.inRefreshRange = entry.isIntersecting;
        });
      },
      {
        rootMargin: `0px 0px 0px 0px`,
      },
    );
  }
  const observer = OBSERVERS["refresh"];

  // Always ensure we're not already observing the element.
  // This ensures if we call `GptAd.reset()` the ad will
  // go through the observer again like the brand new ad it is.
  observer.unobserve(gptAd.element);

  observer.observe(gptAd.element);
}

const BREAKPOINTS = [];

/**
 * Reset all called ads that rely on the changed breakpoint.
 * @param {{width: number}} params - breakpoint width being crossed
 */
function handleCrossBreakpoint({ width }) {
  const gptAds = getAllAds()
    .filter((gptAd) => {
      // We're only concerned with ads that have already been called
      const matchingBreakpoint = gptAd.breakpoints.map((bp) => bp.width).includes(width);
      return matchingBreakpoint && gptAd.called;
    })
    .forEach((gptAd) => {
      gptAd.reset();
    });
}

/**
 * Record each breakpoint an ad uses, and
 * reset affected called ads if the browser crosses it.
 *
 * @param {import("./gpt").GptAd} gptAd
 */
export function bindBreakpointObserver(gptAd) {
  gptAd.breakpoints.forEach((bp) => {
    if (BREAKPOINTS.includes(bp)) {
      return;
    }
    BREAKPOINTS.push(bp);

    window.matchMedia(`(min-width: ${bp.width}px)`).addEventListener("change", (e) => {
      handleCrossBreakpoint({ width: bp.width });
    });

    log(`Registered ad breakpoint at ${bp.width}px`);
  });
}
