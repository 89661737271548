import { getAllAds } from "./models";
import { callAds } from "./gpt";
import { log } from "./utils/log";

let _scrollPos = Math.round(window.scrollY);

export function render() {
  // If the user is scrolling too fast to see ads,
  // don't overload their browser.
  let previousScrollPos = _scrollPos;
  _scrollPos = window.scrollY;
  let scrollDelta = Math.abs(previousScrollPos - _scrollPos);
  if (scrollDelta > 500) {
    log("Scrolling too fast - ad render skipped");
    return;
  }

  // Determine which ads are ready to render
  let adsToRender = getAllAds().filter((gptAd) => {
    return !gptAd.called && gptAd.inLazyRange;
  });

  // @TODO - Just in time targeting
  // @TODO - Performance measurements

  callAds(adsToRender);
}
