import { log } from "../utils/log";
import { getUserData } from "../utils/user";
import { getGeo } from "../utils/geo";

// Store ppid state
let ppid;

/**
 * Get the PPID if its been established.
 * Pull this into the initial GPT config.
 * @returns {string|undefined}
 */
export function getPublisherProvidedId() {
  return ppid;
}

/**
 * Encrypt the message
 * @param {string} message
 * @returns {string|undefined}
 */
async function hash(message) {
  if (!message) return;

  if (window.location.protocol !== "https:") {
    log("[ads] insecure context, will not pass PPID because crypto hashing is unavailable");
    return;
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#basic_example
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hashBuffer = await window.crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
  return hashHex;
}

/**
 * Provide a hashed user ID
 * https://support.google.com/admanager/answer/2880055
 */
export default async function publisherProvidedId() {
  // US only
  const geo = await getGeo();
  if (geo?.country !== "USA") {
    return;
  }

  const user = getUserData();

  const id = user?.["https://sciam.com/user_id"];
  if (!id) return null;
  ppid = await hash(id);
  return;
}
