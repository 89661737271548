import { setKeyValues } from "../models";
import { log } from "./log";

let abTestGroup;

/**
 * Assigns pageview a random number between 1-10
 * @returns {number}
 */
export function getABTestGroup() {
  if (abTestGroup !== undefined) {
    return abTestGroup;
  }

  abTestGroup = Math.floor(Math.random() * 10) + 1;
  log(`[ads] ABT group ${abTestGroup}`);

  // Make this usable for targeting and analytics
  setKeyValues({ abt: abTestGroup.toString() });
  return abTestGroup;
}

// Experiments and Feature Flag checks go below
