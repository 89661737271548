// When a page is read to load ads,
// this manages the process.

import { registerAds } from "./models";
import { loadScript } from "./utils/load";
import {
  GPT_SRC,
  enableGptServices,
  setPageLevelTargeting,
  gptQueue,
  bindGptEventHandlers,
} from "./gpt";
import { log } from "./utils/log";
import { PERF_KEYS } from "./perf";
import { runPlugins } from "./plugins";
import { getConsent } from "./consent";
import { render } from "./rendering";
import { stubTCFv2API } from "./utils/tcfv2";
import { getIsAdFree } from "./plugins/piano";
import { getABTestGroup } from "./utils/testing";
import { renderWithPubwise } from "./plugins/pubwise";
import { enablePubwise } from "./debug";

export async function initAdsLifeCycle() {
  getABTestGroup(); // Ensure test is selected up front
  registerAds();

  // Before GPT, unclear if it listens immediately
  // Feature flagged just in case its mere presence is used to
  // expect it to work.
  if (window.location.search.match(/debug=tcfv2/)) {
    stubTCFv2API();
  }

  // Load GPT
  loadScript(GPT_SRC).then(() => {
    log("GPT loaded");
    performance.mark(PERF_KEYS.gptLoaded);
  });

  await getConsent();
  await runPlugins();

  if (getIsAdFree()) {
    log(`[ads] Premium user, no ads`);
    return;
  }

  gptQueue.push(() => {
    performance.mark(PERF_KEYS.gptQueue);
    log("Started GPT Queue");
    setPageLevelTargeting();
    bindGptEventHandlers();
    enableGptServices();

    // Pubwise is always on.
    // Which makes the else noop at the moment,
    // but I want to retain the ability to run it
    // the old way for a moment.
    const enablePrebid = enablePubwise();
    if (enablePrebid) {
      // Do the first batch ASAP

      renderWithPubwise();
      setInterval(() => {
        registerAds();
        renderWithPubwise();
      }, 250);
    } else {
      // Do the first batch ASAP
      render();

      // Attempt a render every 250ms
      setInterval(() => {
        registerAds();
        render();
      }, 250);
    }
  });
}
