import { loadScript } from "../utils/load";
import { log } from "../utils/log";
import { getAllAds } from "../models";
import { enablePubwise } from "../debug";

/**
 * Load Amazon A9 bidder
 */
function loadA9() {
  log("[ads] stubbed A9");
  !(function (a9, a, p, s, t, A, g) {
    if (a[a9]) return;
    function q(c, r) {
      a[a9]._Q.push([c, r]);
    }
    a[a9] = {
      init: function () {
        q("i", arguments);
      },
      fetchBids: function () {
        q("f", arguments);
      },
      setDisplayBids: function () {},
      targetingKeys: function () {
        return [];
      },
      _Q: [],
    };
    A = p.createElement(s);
    A.async = !0;
    A.src = t;
    g = p.getElementsByTagName(s)[0];
    g.parentNode.insertBefore(A, g);
  })("apstag", window, document, "script", "//c.amazon-adsystem.com/aax2/apstag.js");

  apstag.init({
    pubID: "db83f9c6-a698-4a6a-89fb-c29c85426748", // SCIAM unique publisher id
    adServer: "googletag",
    simplerGPT: true,
  });
}

export function fetchA9Bids(gptAds) {
  log(`[ads] A9 initiated`);

  // Convert to A9 slot format
  let slots = gptAds.map((gptAd) => {
    return {
      slotID: gptAd.id,
      // slotName - TODO?
      sizes: gptAd.getSizesForBreakpoint(),
    };
  });

  // Drop any positions that don't have valid sizes at this breakpoint
  slots = slots.filter((slot) => slot.sizes.length !== 0);
  if (slots.length === 0) {
    return;
  }

  apstag.fetchBids(
    {
      timeout: 2e3,
      // @TODO - to make this work with refresh
      // we'll need to limit the `slots` to the set being registered
      slots,
    },
    function (bids) {
      log(`[ads] A9 bids received`, bids);
      apstag.setDisplayBids();
    },
  );
}

const PUBWISE_SCRIPT =
  "https://fdyn.pubwise.io/script/a55a7abf-7f74-4ad9-be84-babc68a1d991/v3/dyn/pws.js?type=default";

function stubPubwise() {
  window.gptadslots = [];
  window.pwpbjs = window.pwpbjs || {};
  window.pwpbjs.que = window.pwpbjs.que || [];
  window.pubwise = window.pubwise || {};
  window.pubwise.cmd = window.pubwise.cmd || [];
  window.pubwise.que = window.pubwise.que || [];
  window.pubwise.observers = window.pubwise.observers || [];
  window.pubwise.adconfig = window.pubwise.adconfig || [];
  window.CONSENT_SOURCE_NONE = 1;
  window.CONSENT_SOURCE_EXTERNAL = 2;
  window.CONSENT_SOURCE_PUBWISE = 3;
  window.pubwise.enabled = true;
}

export default function pubwise() {
  if (!enablePubwise()) {
    return;
  }

  loadA9();
  stubPubwise();

  return loadScript(PUBWISE_SCRIPT);
}

/**
 * @typedef {import('../models').GptAd} GptAd
 */

const PUBWISE_REGISTERED_ADS = [];

/**
 * Hand ads off to pubwise for bidding and loading.
 * @param {GptAd[]} gptAds
 */
export function renderWithPubwise() {
  // Grab any new ads
  const gptAds = getAllAds().filter((gptAd) => {
    return !gptAd.called && !PUBWISE_REGISTERED_ADS.includes(gptAd.id);
  });

  if (!gptAds.length) return;

  gptAds.forEach((gptAd) => PUBWISE_REGISTERED_ADS.push(gptAd.id));

  log(`[ads] Passing ${gptAds.length} ads to Pubwise`, gptAds);

  fetchA9Bids(gptAds);

  // Fill out the Pubwise object
  gptAds.forEach((gptAd) => {
    window.gptadslots[gptAd.id] = gptAd.slot;
  });

  gptAds.forEach((gptAd) => {
    window.pubwise.renderAd(gptAd.id);
  });
}
