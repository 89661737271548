import { getGptAdById } from "../models";
import { gptQueue } from "../gpt";
import { log } from "../utils/log";
import { IAB_SIZES } from "../constants";

/**
 * Programmatic Order IDs
 */
const ELIBIGLE_ORDER_IDS = [
  -1, 3549025869, 3536025748, 3536049820, 3537870948, 3537833901, 3569811838, 3536052937,
  3569811997, 3536054812, 3535954007, 3569812063, 3536056960, 3537841809, 3537858672, 3535978997,
  3537863664, 3535926893, 3536081272, 3536083342, 3535984229, 3535984232, 3536083408, 3536079766,
  3535984271, 3536083597, 3536083615, 3536083645, 3537817800, 3535984445, 3535984460, 3536083801,
  3535984490, 3535986416, 3535986434, 3535986446, 3535986476, 3536079811, 3535986629, 3536035843,
  3535986680, 3535986149, 3536082226, 3536082229, 3535986875, 3535986887, 3535986182, 3535986908,
  3536082370, 3536083837, 3537822366, 3536083870, 3536083885, 3536083891, 3536082406, 3537870435,
  3537870447, 3536084110, 3536084131, 3536082442, 3536084155, 3536040412, 3537870609, 3536084299,
  3536070925, 3536084335, 3537871095, 3537870654, 3537871185, 3537871326, 3537871362, 3537871377,
  3537826059, 3537871425, 3537871584, 3537871629, 3537871665, 3537871818, 3537870921, 3535941320,
  3537871866, 3537871890, 3536045983, 3537871905, 3537871917, 2133655627,
];

/**
 * These are all house ads and internal marketing
 */
const ELLIGIBLE_ADVERTISER_IDS = [4408541474, 4629260826, 4424821863, 4424821863];

let REFRESH_COUNT = 0;
let REFRESH_TIMING = 30000;
const MAX_REFRESH = 15;

let REFRESH_INTERVAL;

function maxedOut() {
  return REFRESH_COUNT >= MAX_REFRESH;
}

// List of ads to refresh
let REFRESHABLE_ADS = [];

function attemptAdRefresh() {
  if (REFRESHABLE_ADS.length === 0 || document.visibilityState === "hidden") {
    return;
  }

  if (maxedOut()) {
    log(`[ads][refresh] Refresh stopped to avoid excessive ad renders.`);
    clearInterval(REFRESH_INTERVAL);
  }

  let refresh = []; // Ads to reload now
  let remain = []; // Ads that are eligible to refresh but aren't in view

  REFRESHABLE_ADS.forEach((gptAd) => {
    if (gptAd.inRefreshRange) {
      // Safe to refresh
      refresh.push(gptAd);
    } else {
      // Maybe next time
      remain.push(gptAd);
    }
  });

  // Remove all the ads that we're about to refresh from the list
  REFRESHABLE_ADS = remain;

  if (!refresh.length) {
    return;
  }

  log(`[ads][refresh] Refreshing ${refresh.length} ads.`, refresh);

  // Increase the count
  REFRESH_COUNT += refresh.length;

  refresh.forEach((gptAd) => {
    lockSizeAtBreakpoint(gptAd);
    gptAd.reset();
  });
}

/**
 * We only ever want to refresh ads at the same IAB size
 * to avoid the frame jumping.
 * @param {import("../gpt").GptAd} gptAd
 */
function lockSizeAtBreakpoint(gptAd) {
  let currentBreakPoint = 0;
  for (let bp of gptAd.breakpoints) {
    if (bp.width <= window.innerWidth) {
      currentBreakPoint = bp.width;
    }
  }

  gptAd.element.setAttribute(`sizes-from-${currentBreakPoint}`, gptAd.size.join("x"));

  // Increment `refresh` key/value with each refresh.
  let refreshKeyValue = parseInt(gptAd.element.getAttribute("targeting-refresh") || 0) + 1;
  gptAd.element.setAttribute("targeting-refresh", refreshKeyValue);
}

/**
 *
 * @param {import("../gpt").GptAd} gptAd
 * @returns {[boolean, string]} - May refresh, and the reason
 */
function getIsAdAllowedToRefresh(gptAd) {
  // Don't refresh fluid or native sizes
  const isIABSize = IAB_SIZES.includes(gptAd.size.join("x"));

  if (!isIABSize) {
    return [false, "not IAB size"];
  }

  // Is refresh-elligible Order or AdX
  // AdX refresh must be declared per the TOS https://support.google.com/admanager/answer/6286179?hl=en&sjid=16773587030558682810-NA
  // this
  const isAdX = !gptAd.slotRenderedEvent.creativeId;
  if (isAdX) {
    return [false, "is AdX"];
  }

  // House ads can always refresh
  let isHouseAd = ELLIGIBLE_ADVERTISER_IDS.includes(gptAd.slotRenderedEvent.advertiserId);
  if (isHouseAd) {
    return [true, "house ad"];
  }

  // Programmatic orders that are allowed to refresh
  let isRefreshableOrder = ELIBIGLE_ORDER_IDS.includes(gptAd.slotRenderedEvent.campaignId);
  return [isRefreshableOrder, isRefreshableOrder ? "in order list" : "not in order list"];
}

/**
 * Refresh the ads, but don't be a jerk about it.
 */
export default function RefreshPlugin() {
  gptQueue.push(() => {
    googletag.pubads().addEventListener("impressionViewable", (e) => {
      if (!e?.slot) {
        return;
      }

      if (maxedOut()) {
        return;
      }

      const gptAd = getGptAdById(e.slot.getSlotElementId());

      // Ads may refresh while in view X seconds after a viewable impression
      const [allowed, explanation] = getIsAdAllowedToRefresh(gptAd);
      log(
        allowed
          ? `[ads][refresh] ${gptAd.id} is refresh elligible in ${REFRESH_TIMING}ms ♻️. \n- Reason: ${explanation}`
          : `[ads][refresh] ${gptAd.id} is NOT refresh elligible. \n- Reason: ${explanation}`,
      );
      if (allowed) {
        setTimeout(() => {
          log(`[ads][refresh] ${gptAd.id} is now ready to refresh. ♻️`);
          REFRESHABLE_ADS.push(gptAd);
        }, REFRESH_TIMING);
      }
    });

    REFRESH_INTERVAL = setInterval(attemptAdRefresh, 5000);
  });
}
