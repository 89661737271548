import { loadScript } from "../utils/load";

export default function polar() {
  const src = "https://cdn.mediavoice.com/nativeads/script/scientificamerican/pl-track.js";

  // @TODO make this part of the config, not URL bound
  const isSponsorContent = window.location.pathname.startsWith("/custom-media/");
  if (isSponsorContent) {
    loadScript(src);
  }
}
