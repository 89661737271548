// Internal state for ads on the page
import { log } from "./utils/log";
import { defineSlot, gptQueue } from "./gpt";
import {
  bindBreakpointObserver,
  bindLazyLoadIntersectionObserver,
  bindRefreshLoadIntersectionObserver,
} from "./observers";
import { debugExcludeSizes } from "./debug";

let adCounter = 0;

// Store of known ads
let gptAds = [];

// Global config state
let adsConfig = {
  unitpath: "",
  targeting: {},
};

/**
 * Add extra key values to page level targeting
 * @param {Object.<string, (string|string[])>} params - key/value targeting
 */
export function setKeyValues(params) {
  for (let key in params) {
    adsConfig.targeting[key] = params[key];
  }
}

export function setAdsConfig(newConfig, reset = false) {
  if (reset) {
    adsConfig = newConfig;
  } else {
    Object.assign(adsConfig, newConfig);
  }

  if (!adsConfig.unitpath) {
    console.error("unitpath was not set. Ads will not work.");
  }
}

export function getAdsConfig() {
  return adsConfig;
}

export class GptAd {
  constructor(gptElement) {
    gptElement.classList.add("is-registered");

    this.element = gptElement;
    this.getPropertiesFromElement();
    this.setId();

    this.slotRenderedEvent = null;
    this.called = false;
    this.loaded = false;
    this.empty = false;
    this.viewableImpression = false;
    this.size = null;

    // This can't happen until GPT is ready
    gptQueue.push(() => {
      this.slot = defineSlot(this);
    });

    // Intersection observer will control whether it's in the lazy-range
    this.inLazyRange = false;
    this.inRefreshRange = false;
    bindLazyLoadIntersectionObserver(this);
    bindRefreshLoadIntersectionObserver(this);
    bindBreakpointObserver(this);
  }

  /**
   * Clear out the ad and make it eligible for refresh.
   */
  reset() {
    const id = this.element.id;
    this.element.removeAttribute("id");

    // Remove the registered class
    // Other classes will be cleaned up when the next ad loads
    this.element.classList.remove("is-registered");

    // Forget it, effectively destroying this instance
    gptAds.splice(gptAds.indexOf(this), 1);

    // Wait a beat then re-register the ads
    log(`[ad] reset for ${id}`);
    requestAnimationFrame(() => {
      registerAds();
    });
  }

  /**
   * Convert HTML into data structure
   */
  getPropertiesFromElement() {
    // UNIT PATH
    // is the location of the ad on the site for the purpose
    // of targeting and analytics. Ads might have more detailed paths
    // than their parent page, but don't have to.
    this.unitpath = adsConfig.unitpath;
    if (this.element.getAttribute("unitpath")) {
      this.unitpath = this.unitpath + "/" + this.element.getAttribute("unitpath");
    }

    const attributes = Object.values(this.element.attributes);

    // Key Value Targeting
    // targeting is used by DFP to target specific inventory.
    this.targeting = {};
    attributes
      .filter((attr) => attr.name.startsWith("targeting-"))
      .forEach((attr) => {
        let key = attr.name.replace(/^targeting-/, "");
        this.targeting[key] = attr.value;
      });

    // Breakpoints
    // The size of ads that can serve at each breakpoint AND UP.
    this.breakpoints = [];
    attributes
      .filter((attr) => attr.name.startsWith("sizes-from-"))
      .forEach((attr) => {
        let width = parseInt(attr.name.replace(/^sizes-from-/, ""), 10);
        let sizes = attr.value.split(",");
        sizes = debugExcludeSizes(sizes); // Testing helper
        this.breakpoints.push({ width, sizes });
      });

    // Sort the breakpoints, just in case
    this.breakpoints = this.breakpoints.sort((a, b) => {
      return a.width - b.width;
    });
  }

  setId() {
    adCounter++;
    const template = this.element.getAttribute("id-format") || "gpt-unit-{}";
    const id = template.replace("{}", adCounter);
    this.id = id;
    this.element.setAttribute("id", id);
  }

  getSizesForBreakpoint() {
    let sizes = [];
    for (let bp of this.breakpoints) {
      if (bp.width <= window.innerWidth) {
        sizes = bp.sizes;
      }
    }
    return sizes;
  }
}

export function registerAds() {
  [...document.querySelectorAll("gpt-ad:not(.is-registered)")].forEach((el) => {
    let ad = new GptAd(el);
    gptAds.push(ad);
    log(`Registered <gpt-ad> for ${ad.id}`, ad);
  });
}

export function getGptAdById(id) {
  return gptAds.find((ad) => {
    return ad.id === id;
  });
}

export function getAllAds() {
  return gptAds;
}
