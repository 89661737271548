import { jwtDecode } from "jwt-decode";

/**
 * Get the JWT contents
 * @returns {object|null}
 */
export function getUserData() {
  const auth0Cookie = document.cookie
    .split(";")
    .map((c) => c.trim())
    .filter((c) => c.startsWith("sa_piano_auth"))[0];

  if (!auth0Cookie) {
    return null;
  }

  const cookieValue = auth0Cookie.split("=")[1];
  const data = jwtDecode(cookieValue);
  return data;
}
