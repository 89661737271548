import { isOptedOut } from "./consent";
import { log } from "./utils/log";
import { getAdsConfig, getGptAdById } from "./models";
import { IAB_SIZES, PREMIUM_SIZES } from "./constants";
import { trackPerformanceFirstCall, trackPerformanceFirstLoad } from "./perf";
import { getPublisherProvidedId } from "./plugins/ppid";

/**
 * @typedef {import('./models').GptAd} GptAd
 */

export const GPT_SRC = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";

// Event queues
window.googletag = window.googletag || {};
window.googletag.cmd = window.googletag.cmd || [];

/**
 * GPT Queue likes to swallow excpetions silently.
 * This makes debugging really hard. Patch that.
 *
 * @type {Array}
 */
export const gptQueue = [];
/**
 * @param {function} fn
 * @returns
 */
gptQueue.push = (fn) => {
  window.googletag.cmd.push(() => {
    try {
      fn();
    } catch (e) {
      console.log(e);
    }
  });
};

/**
 * Converts a string format size into what GPT expects
 * @param {string} sizeStr
 * @returns {string|number[]} - GPT friendly size
 */
function parseSize(sizeStr) {
  if (sizeStr === "fluid") {
    return sizeStr;
  }

  let size = sizeStr.split("x").map((num) => parseInt(num, 10));
  return size;
}

/**
 * Convert the GPT data structure we store internally
 * into GPT's "Slot" format
 * @param {GptAd} gptAd
 */
export function defineSlot(gptAd) {
  // Odd: https://developers.google.com/publisher-tag/guides/ad-sizes?hl=en#responsive_ads
  //      fixed size is now expected even when there's a sizeMapping. This is duplicative,
  //      but there's a warning if I leave it off. @Todo investigate later.
  const slot = googletag.defineSlot(gptAd.unitpath, gptAd.getSizesForBreakpoint(), gptAd.id);
  const responsiveSizeMapping = googletag.sizeMapping();
  // @see https://developers.google.com/publisher-tag/reference#googletag.SizeMappingBuilder_addSize
  gptAd.breakpoints.forEach((breakpoint) => {
    responsiveSizeMapping.addSize([breakpoint.width, 0], breakpoint.sizes.map(parseSize));
  });
  slot.defineSizeMapping(responsiveSizeMapping.build());

  // Slot level targeting
  for (let key in gptAd.targeting) {
    const value = gptAd.targeting[key];
    // Filter out nulls/empty strings etc
    if (value) {
      slot.setTargeting(key, gptAd.targeting[key]);
    }
  }

  // Connect it to GPT
  slot.addService(googletag.pubads());
  googletag.display(gptAd.id);

  log(`[ads] GPT slot defined for ${gptAd.id}`, gptAd, slot);

  return slot;
}

export function setPageLevelTargeting() {
  const targeting = getAdsConfig().targeting;
  for (let key in targeting) {
    const value = targeting[key];
    // Filter out nulls/empty strings etc
    if (value) {
      log(`[ads] GPT targeting set: ${key}=${targeting[key]}`);
      googletag.pubads().setTargeting(key, targeting[key]);
    }
  }
}

// NB: The order of GPT events is important.
// @see: https://developers.google.com/publisher-tag/common_implementation_mistakes#scenario-5:-mis-ordering-calls-to-gpt
export function enableGptServices() {
  log("[ads] GPT Services enabled");

  // Allows for lazy-load
  googletag.pubads().enableSingleRequest();
  googletag.pubads().disableInitialLoad();

  // If there's no ad don't hold the spot
  googletag.pubads().collapseEmptyDivs();

  // @see: https://developers.google.com/publisher-tag/reference#googletag.privacysettingsconfig
  googletag.pubads().setPrivacySettings({
    nonPersonalizedAds: isOptedOut(),
    // childDirectedTreatment: false, // @TODO: maybe set this if we can categorize institutions some day
  });

  const ppid = getPublisherProvidedId();
  if (ppid) {
    googletag.pubads().setPublisherProvidedId(ppid);
    log("[ads] PPID enabled");
  }

  googletag.enableServices();
}

export function bindGptEventHandlers() {
  googletag.pubads().addEventListener("slotRequested", (event) => {
    trackPerformanceFirstCall();
    const gptAd = getGptAdById(event.slot.getSlotElementId());
    gptAd.called = true;
    gptAd.element.classList.add("is-called");
  });

  googletag.pubads().addEventListener("slotRenderEnded", (event) => {
    trackPerformanceFirstLoad();
    const gptAd = getGptAdById(event.slot.getSlotElementId());
    gptAd.slotRenderedEvent = event; // Store this

    // These are the classes an ad CAN have. Now that we refresh,
    // we made need to take some off.
    const possibleClasses = [
      // No way we got here without these
      "is-called",
      "is-registered",

      "is-empty",
      "is-loaded",
      "is-custom-size",
      "is-fluid-size",
      "is-standard-size",
    ];

    let desiredClasses = ["is-registered", "is-called"];

    // Did it load?
    desiredClasses.push(event.isEmpty ? "is-empty" : "is-loaded");

    gptAd.loaded = true;

    // What is the ad size?
    if (event.size) {
      gptAd.size = event.size;
      let size = event.size;
      if (Array.isArray(size)) {
        size = size.join("x");
      }
      if (IAB_SIZES.includes(size)) {
        desiredClasses.push("is-standard-size");
      } else if (size === "fluid") {
        desiredClasses.push("is-fluid-size");
      } else if (PREMIUM_SIZES.includes(size)) {
        desiredClasses.push("is-premium-size");
      } else {
        desiredClasses.push("is-custom-size");
      }
    }

    // Add the intended classes, remove everything else
    possibleClasses.forEach((cls) => {
      if (desiredClasses.includes(cls)) {
        gptAd.element.classList.add(cls);
      } else {
        gptAd.element.classList.remove(cls);
      }
    });

    if (event.isEmpty) {
      log(`[ads] ${gptAd.id}: rendered empty`);
      return;
    }

    log(`[ads] ${gptAd.id}: loaded as ${event.size.join("x")}`);
  });

  googletag.pubads().addEventListener("impressionViewable", (event) => {
    const gptAd = getGptAdById(event.slot.getSlotElementId());
    gptAd.viewableImpression = true;
    log(`[ads] ${gptAd.id}: viewable-impression`);
  });
}

/**
 * @param {GptAd[]} gptAds
 */
export function callAds(gptAds) {
  // @TODO this should be something totally different
  if (!gptAds.length) return;

  log("[ads] called:", gptAds.map((ad) => ad.id).join(", "));

  const slots = gptAds.map((gptAd) => gptAd.slot);
  gptAds.forEach((ad) => {
    ad.called = true;
  });
  googletag.pubads().refresh(slots);
}
