import { log } from "./utils/log";

const params = new URLSearchParams(window.location.search);

let excludedSizes = undefined;

export function enablePubwise() {
  return !window.location.search.match(/debug=disableprebid/);
}

/**
 * Use ?debug:ads-exclude=2x2 to filter out sizes for testing.
 *
 * @param {string[]} sizes
 * @returns
 */
export function debugExcludeSizes(sizes) {
  // Nothing to do if this isn't even meant to serve at this breakpoint
  if (sizes.length === 0) {
    return;
  }

  if (excludedSizes === undefined) {
    excludedSizes = (params.get("debug:ads-exclude") || "").split(",");
    if (excludedSizes.length) {
      log(`[ads] debug:ads-exclude active, filtering out sizes`, excludedSizes);
    }
  }

  if (excludedSizes === null) {
    return sizes;
  }

  sizes = sizes.filter((size) => {
    return excludedSizes.indexOf(size) === -1;
  });

  // If we remove the last size from the list the ad will not be called.
  // For debugging, we're testing the case of the ad not filling, so we want
  // it to call and come back with an isEmpty. Add a size that will never be filled.
  if (sizes.length === 0) {
    sizes.push("404x1");
  }

  return sizes;
}
