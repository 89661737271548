import { setKeyValues } from "../models";
import { log } from "../utils/log";
import { getUserData } from "../utils/user";

/**
 * For caching state on a given session
 *
 * @param {string} uid - User ID. This way if you switch accounts we know the difference.
 */
function getStateKey(uid) {
  return `_ad_user_state-${uid}`;
}

let state = {
  resources: [],
};

function saveState(uid) {
  sessionStorage.setItem(getStateKey(uid), JSON.stringify(state));
}

function loadState(uid) {
  try {
    let data = sessionStorage.getItem(getStateKey(uid));
    if (data) {
      state = JSON.parse(data);
      return true;
    }
  } catch (e) {
    console.error(e);
  }
  return false;
}

export default function userDataTargeting() {
  return new Promise((resolve) => {
    const user = getUserData();

    // No user, has no entitlements or resources
    if (!user) {
      log(`[ads] user not logged in`);
      state.resources = [];
      setKeyValues({ entitlements: ["no"] });
      return resolve();
    }

    const uid = user["https://sciam.com/user_id"];

    // User state is cached for the session on logged in users
    const hasCachedState = loadState(uid);
    if (hasCachedState) {
      log(`[ads] using entitlements from session cache`, state);
      resolve();
    }

    // Cached or not, we want to update the cache in the background

    // Otherwise, ask Piano.
    window.tp = window.tp || [];
    window.tp.push([
      "init",
      () => {
        window.tp.api.callApi("/access/list", undefined, ({ data }) => {
          state.resources = data?.map((entry) => entry.resource.rid);
          log(`[ads] retrieved user entitlements from API`, state);
          setKeyValues({ entitlements: state.resources });
          saveState(uid);
          resolve();
        });
      },
    ]);
  });
}

/**
 * Only reliable after the code runs the first time.
 * @returns {boolean} - Should we just stop the whole ad-loading process?
 */
export function getIsAdFree() {
  return (state.resources || []).includes("UNLMTD");
}
