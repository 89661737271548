let geoData;

/**
 * Get geo information. Caches.
 * @returns {{country: string, city: string, continent: string, timezone: string}|undefined}
 */
export async function getGeo() {
  if (geoData) {
    return geoData;
  }

  try {
    geoData = await fetch("/api/geo/").then((res) => {
      return res.json();
    });
  } catch (e) {
    console.error(e);
  }

  return geoData;
}
