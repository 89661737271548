/**
 * Load a script tag
 *
 * @param {string} src
 * @param {boolean} async
 * @returns {Promise}
 */
export function loadScript(src, async = true) {
  const script = document.createElement("script");
  script.src = src;
  if (async) {
    script.setAttribute("async", 1);
  }

  // Returning a promise makes these awaitable
  return new Promise((resolve, reject) => {
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
}
