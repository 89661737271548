import { log } from "./sciads/utils/log";

import { handleCommand } from "./sciads/commands";
import { PERF_KEYS } from "./sciads/perf";

performance.mark(PERF_KEYS.libLoaded);

function createApi() {
  window.__ads = window.__ads || [];
  window.__ads.push = handleCommand;
  window.__ads.forEach(handleCommand);
}

function init() {
  log("Hello SciAds");

  createApi();
}

init();
