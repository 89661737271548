import { setKeyValues } from "../models";

/**
 * Apply any key/values passed as ?adkv_{key}={value}
 * to the page-level targeting.
 */
export default function testingKeyValues() {
  const params = new URLSearchParams(window.location.search);
  let targeting = {};
  [...params.keys()]
    .filter((key) => {
      return key.startsWith("adkv_");
    })
    .forEach((key) => {
      let value = params.getAll(key);
      let tkey = key.replace("adkv_", "", 1);
      targeting[tkey] = value;
    });
  setKeyValues(targeting);
}
