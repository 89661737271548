import { log } from "./utils/log";

// Keep these together enum-style for ease of use
export const PERF_KEYS = {
  libLoaded: "ads:library_loaded",
  consent: "ads:consent_determined",
  measureConsent: "ads:time_to_consent",
  page: "ads:page_requested",
  gptLoaded: "ads:gpt_loaded",
  gptQueue: "ads:gpt_queue_init",
  firstCall: "ads:first_call",
  firstLoaded: "ads:first_loaded",
  measureFirstCall: "ads:time_to_first_call",
  measureFirstLoad: "ads:time_to_first_ad",
};

/**
 * Log named measurement to debug console.
 * @param {string} key - performance entry name for a measurement
 * @param {string} message - Text to log describing the metric
 */
export function logMeasurement(key, message) {
  let t = Math.round(performance.getEntriesByName(key)[0].duration);
  log(`${message}: ${t}ms`);
}

export function trackPerformanceFirstCall(gptAd) {
  if (!performance.getEntriesByName(PERF_KEYS.firstCall).length) {
    performance.mark(PERF_KEYS.firstCall);
    performance.measure(PERF_KEYS.measureFirstCall, PERF_KEYS.page, PERF_KEYS.firstCall);
  }
}

export function trackPerformanceFirstLoad(gptAd) {
  if (!performance.getEntriesByName(PERF_KEYS.firstLoaded).length) {
    performance.mark(PERF_KEYS.firstLoaded);
    performance.measure(PERF_KEYS.measureFirstLoad, PERF_KEYS.page, PERF_KEYS.firstLoaded);
    logMeasurement(PERF_KEYS.measureFirstLoad, "Time to first ad");
    let t = Math.round(performance.getEntriesByName(PERF_KEYS.measureFirstLoad)[0].duration);
  }
}
