// @ts-check

import { log } from "./utils/log";
import { setAdsConfig } from "./models";
import { initAdsLifeCycle } from "./controller";
import { PERF_KEYS } from "./perf";
import { getGptAdById } from "./models";

/**
 * @typedef {import('../types').adsConfig} adsConfig
 */

/**
 * These are functions the page can call with __ads.push
 *
 * They can take an object of parameters.
 *
 */
export const COMMANDS = {
  /**
   * The "pagview" describes the page fo adtech
   * purposes. This starts the lifecycle.
   *
   * @param {adsConfig} adsConfig
   */
  page: function (adsConfig) {
    performance.mark(PERF_KEYS.page);
    setAdsConfig(adsConfig, true);
    initAdsLifeCycle();
  },

  get: function (id) {
    console.log(getGptAdById(id));
  },
};

export function handleCommand(cmdArr) {
  const [cmd, args] = cmdArr;
  log(`SciAds command "${cmd}"`, args);
  const callback = COMMANDS[cmd];
  if (callback) {
    callback(args);
  }
}
