import { log } from "../utils/log";
import { isOptedOut } from "../consent";

export default function upsapi() {
  log("[ads] creating __uspapi");

  // Setup IAB API
  window.__uspapi = function (command, versionRequested, callback) {
    if (command !== "getUSPData") {
      console.error(`Command <${command}> not implemented. Only <getUSPData> is supported.`);
      return;
    }

    // See https://github.com/InteractiveAdvertisingBureau/CCPA-reference-code
    const version = 1;
    const noticeGiven = "Y";
    const optedOut = isOptedOut() ? "Y" : "N";
    const limited_service_provider_agreement_member = "-"; // Asking if we're covered, but we probably aren't.

    callback(
      {
        version: version,
        uspString: [version, noticeGiven, optedOut, limited_service_provider_agreement_member].join(
          ""
        ),
      },
      true
    );
  };
}
