import { PERF_KEYS, logMeasurement } from "./perf";
import { readCookie } from "./utils/cookies";
import { log } from "./utils/log";

// Handles OneTrust integration
let optOuts = {
  ads: false,
};

export function isOptedOut() {
  return optOuts.ads;
}

window.consentQueue = window.consentQueue || [];

export function getConsent() {
  return new Promise((resolve) => {
    // GPC support
    if (navigator.globalPrivacyControl) {
      optOuts.ads = true;
      resolve();
    }

    // Worst case, wait for OneTrust to load and provide consent groups
    // for a first time visitor.
    window.consentQueue.push(({ consent }) => {
      optOuts.ads = !consent.targeting;

      resolve();
    });
  }).then(() => {
    // Measure when done
    performance.mark(PERF_KEYS.consent);
    performance.measure(PERF_KEYS.measureConsent, PERF_KEYS.libLoaded, PERF_KEYS.consent);
    logMeasurement(PERF_KEYS.measureConsent, "Time to determine consent status");
    if (isOptedOut()) {
      log(`Ads: user opted out. Showing nonpersonalized ads only.`);
    }
  });
}
